import React, { Component } from "react";
import { render } from "react-dom";
import MyForm from "./myForm";
import Logo from "./assets/img/logo.png";
import "./styles/main.scss";

class App extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <main>
        <header>
          <a href="https://startfinance.ro/">
            <img src={Logo} alt="Logo" className="logo" />
          </a>
          <div className="long-title">
            <span>Programeaza o </span>
            <span>intalnire</span>
          </div>
          <a className="back-btn" href="https://startfinance.ro/">Inapoi</a>
        </header>
        <div className="form-wrapper">
          <p>
            Pentru a putea stabili o intalnire, avem nevoie de 1 minut din timpul tau.
          </p>
          <MyForm />
        </div>
        <footer>
          <span>
            © Copyright {new Date().getFullYear()} | Powered by{" "}
            <a href="https://noha.ro/">Noha</a>
          </span>
        </footer>
      </main>
    );
  }
}

render(<App />, document.getElementById("root"));
